@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
/* #region change values */

$primary: #00446d;
$font-family-sans-serif: "Inter", sans-serif;

/* #endregion */

@import "~bootstrap/scss/bootstrap";

button {
  background-color: rgba(255, 255, 255, 0);
}

.toast {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 10000;
}


.split-horizontal {
  display: flex;
  width: 100%;
  height: 100%;
}
.split-vertical {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.gutter {
   flex-basis: 2px !important;
  flex-shrink: 0;
  flex-grow: 0;
  background: gray;
}

.gutter-horizontal {
  cursor: col-resize;
}
.gutter-vertical {
  cursor: row-resize;
}
.gutter:hover {
  flex-basis: 5px !important;
  background: #8cF;
}
.gutter-dragging:hover {
  background: cyan;
}

.pane {
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
 
  position: relative;
 
}
.simple-vertical {
  height: 100%;
}

 

.new-template .browser .browser_wrapper .canves-content-wrapper .content .canves-content-children > * {
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  pointer-events: all;
}

.new-template .browser .browser_wrapper .canves-content-wrapper .content:hover .canves-content-children {
  border: 2px dashed #007bff;
}

.new-template .browser .browser_wrapper .canves-content-wrapper .content .info {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 11px;
  padding: 2px 8px;
  display: none;
  pointer-events: none;
}

.new-template .browser .browser_wrapper .canves-content-wrapper .content:hover .info {
  display: block;
}

.tmplate-multiline-text {
  line-break: anywhere;
}