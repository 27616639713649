@font-face {
  font-family: Times New Roman;
  src: url('./fonts/times.ttf');
}
@font-face {
  font-family: Arial;
  src: url('./fonts/arial.ttf');
}
@font-face {
  font-family: Verdana;
  src: url('./fonts/verdana.ttf');
}
@font-face {
  font-family: Calibri;
  src: url('./fonts/calibri.ttf');
}
@font-face {
  font-family: eorgia;
  src: url('./fonts/georgia.ttf');
}
@font-face {
  font-family: Open Sans;
  src: url('./fonts/Open_Sans/OpenSans-Regular.ttf');
}
@font-face {
  font-family: Roboto;
  src: url('./fonts/roboto/Roboto-Regular.ttf');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
